"use client";
// log specific events happening.

declare global {
  interface Window {
    gtag: any;
  }
}

export const gtagEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category?: string;
  label?: string;
  value?: any;
}) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
