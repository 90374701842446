"use client";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { usePathname } from "next/navigation";

import menuData from "@/content/menuData";
import { downArrow } from "@/designs/svgIcons";
import getConfigs from "@/config/base";

const Header = () => {
  const pathname = usePathname();

  const subMenu = {
    Solutions: ["/returns", "/liquidation"],
    Resources: ["/faq", "/blog"],
    Company: ["/about", "/careers"],
    Nonprofit: ["/shop"],
  };

  // Navbar toggle
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navbarToggleHandler = () => {
    setNavbarOpen(!navbarOpen);
  };

  // submenu handler
  const [openIndex, setOpenIndex] = useState(-1);
  const handleSubmenu = (index) => {
    if (openIndex === index) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <section className="mb-18 relative">
      <header
        className={`header !fixed top-0   z-20 flex w-full  items-center  !bg-white bg-transparent !bg-opacity-80 shadow-sticky backdrop-blur-sm !transition `}
      >
        <div className="container mx-auto">
          <div className="relative  flex items-center justify-between">
            <div className="w-60 max-w-full px-4 2xl:mr-12">
              <Link
                onClick={navbarToggleHandler}
                href="/"
                className={`header-logo block w-full py-2 lg:py-2 `}
              >
                <div className=" flex items-center justify-center gap-x-1">
                  <Image
                    src="/images/logo/logo.svg"
                    alt="logo"
                    width={200}
                    height={20}
                  />
                </div>
              </Link>
            </div>

            <div className=" flex w-full items-center justify-end gap-x-1 pr-0 lg:pr-0">
              <div className="py-8 lg:py-0">
                <button
                  onClick={navbarToggleHandler}
                  id="navbarToggler"
                  aria-label="Mobile Menu"
                  className="absolute right-4 top-1/2 block translate-y-[-50%] rounded-lg px-3 py-[6px]   lg:hidden"
                >
                  <span
                    className={`relative my-1 block h-1 w-[30px] bg-primary transition-all duration-300 ${
                      navbarOpen ? " top-[7px] rotate-45" : " "
                    }`}
                  />
                  <span
                    className={`w-[30px relative my-1 block h-1 bg-primary transition-all duration-300  ${
                      navbarOpen ? "opacity-0 " : " "
                    }`}
                  />
                  <span
                    className={`relative my-1 block h-1 w-[30px] bg-primary transition-all duration-300 ${
                      navbarOpen ? " top-[-8px] -rotate-45" : " "
                    }`}
                  />
                </button>
                <nav
                  id="navbarCollapse"
                  className={`navbar absolute right-0 z-30 w-[250px] rounded border-[.5px] border-body-color/50 bg-white px-6 py-4 duration-300  lg:visible lg:static lg:w-auto lg:border-none lg:!bg-transparent lg:p-0 lg:opacity-100 ${
                    navbarOpen
                      ? "visibility top-full opacity-100"
                      : "invisible top-[120%] opacity-0"
                  }`}
                >
                  <ul className="block w-full lg:flex lg:space-x-12">
                    {menuData.map((menuItem, index) => (
                      <li
                        key={menuItem.id}
                        className={`group relative mx-[.7rem!important] 2xl:mx-[1rem!important]   
                      `}
                      >
                        {!menuItem.newTab ? (
                          menuItem.path ? (
                            <Link
                              href={menuItem.path}
                              as={menuItem.path}
                              onClick={navbarToggleHandler}
                              className={`flex py-2 text-base ${
                                pathname === menuItem.path &&
                                "mx-0  text-primary underline underline-offset-4"
                              }
                             font-semibold  uppercase group-hover:opacity-70 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6`}
                            >
                              {menuItem.title}
                            </Link>
                          ) : (
                            <a
                              href={menuItem.url}
                              target="__blank"
                              className={`flex py-2 text-base
                             font-semibold  uppercase group-hover:opacity-70 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6`}
                            >
                              {menuItem.title}
                            </a>
                          )
                        ) : (
                          <>
                            <a
                              onClick={() => handleSubmenu(index)}
                              className={`flex cursor-pointer items-center justify-start py-2 text-base ${
                                subMenu[menuItem.title].indexOf(pathname) !==
                                  -1 && "font-bold text-primary"
                              }  mx-0 font-semibold uppercase  group-hover:opacity-70 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6`}
                            >
                              {menuItem.title}
                              <span>{downArrow}</span>
                            </a>
                            <div
                              className={`submenu  relative left-0 top-full  rounded-md bg-white transition-[top] duration-300 group-hover:opacity-100 lg:invisible lg:absolute lg:top-[110%] lg:block lg:w-[250px] lg:p-4 lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full ${
                                openIndex === index ? "block" : "hidden"
                              }`}
                            >
                              {menuItem.submenu.map((submenuItem) => (
                                <Link
                                  onClick={navbarToggleHandler}
                                  href={
                                    submenuItem.path
                                      ? submenuItem.path
                                      : submenuItem.url
                                  }
                                  target={submenuItem.url ? "_blank" : "_self"}
                                  as={submenuItem.path}
                                  key={submenuItem.id}
                                  className={` ${
                                    pathname === submenuItem.path &&
                                    "font-semibold text-primary underline underline-offset-4"
                                  } block rounded py-2.5 text-sm  font-semibold  uppercase  hover:opacity-70 lg:px-3`}
                                >
                                  {submenuItem.title}
                                </Link>
                              ))}
                            </div>
                          </>
                        )}
                      </li>
                    ))}
                    <li className="group relative flex items-center justify-center  rounded-xl border-2 border-primary  py-2 text-base font-bold text-primary lg:hidden">
                      <a
                        href={new URL("/login", getConfigs().appSiteURL).href}
                        className="flex 
                             cursor-pointer  group-hover:opacity-70 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6"
                      >
                        Login
                      </a>
                    </li>
                    <li className="group relative mt-2 flex w-full items-center  justify-center rounded-xl border-2 border-primary  bg-primary py-2 text-base font-bold text-white lg:hidden">
                      <Link
                        href="/demo"
                        className="flex w-full  justify-center 
                             text-center  text-base group-hover:opacity-70 lg:mr-0 lg:inline-flex lg:px-0 lg:py-6"
                      >
                        Book a Demo
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <a
                href={new URL("/login", getConfigs().appSiteURL).href}
                className="ease-in-up  hidden flex-row  gap-x-2 rounded-md border-2  border-primary px-5  py-2 text-base font-bold text-primary transition duration-300 hover:bg-primary hover:bg-opacity-90 hover:text-white hover:shadow-signUp md:px-9 lg:flex lg:px-2 2xl:px-9"
              >
                <span>Login</span>
              </a>
              <Link
                href="/demo"
                className="ease-in-up hidden flex-row gap-x-2 rounded-md bg-primary px-5 py-[.60rem] text-base font-bold text-white transition duration-300  hover:bg-primary/80  hover:bg-none  hover:shadow-signUp  md:px-9 lg:flex lg:px-4 2xl:px-8"
              >
                <span className="max-xl:hidden">Book a</span>
                <span>Demo</span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
};

export default Header;
