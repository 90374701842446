import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { toast } from "react-toastify";
import { gtagEvent } from "./googleAnalyticsEvents";

const CREATE_SUBSCRIPTION_MUTATION = gql`
    mutation createSubscription($input: SubscriptionInput) {
        createSubscription(input: $input)
    }
`;
const SUBMIT_FORM = gql`
    mutation submitForm($input: FormInput!) {
        submitForm(input: $input)
    }
`;
const useGraphQlQuery = () => {
  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION_MUTATION);
  const [submitFormData] = useMutation(SUBMIT_FORM);

  const subscribe = async ({ email, token, reset }) => {
    try {
      await createSubscription({
        variables: {
          input: {
            email: email,
            token,
          },
        },
      });
      toast.success("Subscribed successfully!");
      if (typeof reset === "function") {
        reset();
      }
    } catch (err) {
      toast.error(`An error occurred: ${err.message}`);
    }
  };

  const submitForm = async ({ formData, reset, formName }) => {
    try {
      await submitFormData({
        variables: {
          input: {
            formName: `${formName}`,
            fields: Object.entries(formData).map(([key, value]) => ({
              fieldName: key,
              value: value,
              dataType: "text",
            })),
          },
        },
      });
      toast.success("Form submitted successfully!");
      gtagEvent({ action: "form_submitted", category: formName });
      if (typeof reset === "function") {
        reset();
      }
    } catch (err) {
      toast.error(`An error occurred: ${err.message}`);
    }
  };

  return { subscribe, submitForm };
};

export default useGraphQlQuery;
